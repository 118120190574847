import { getAvailablePaymentMethods } from '@shopware-pwa/api-client';
import { computed } from 'vue';

export function usePaymentMethods() {
  const { apiInstance } = useShopwareContext();

  async function getPaymentMethods(
    { onlyAvailable } = { onlyAvailable: true }
  ) {
    const response = await getAvailablePaymentMethods(apiInstance, {
      onlyAvailable,
    });
    return computed(() => {
      return response?.elements.filter((element) => element.active) || [];
    });
  }

  return { getPaymentMethods };
}
