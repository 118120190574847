<script lang="ts">
export default { name: 'DefaultLayout' };

//TODO: import from design-system
export enum EModalForm {
  IS_LOGIN_FORM = 'is-login-form',
  IS_RESET_PW_FORM = 'is-reset-pw-form',
  IS_REGISTRATION_FORM = 'is-registration-form',
}
</script>
<script setup lang="ts">
import {
  removeCartItem,
  changeCartItemQuantity,
} from '@shopware-pwa/api-client';
import { composeProductBadges } from '@/helpers/composeProductBadges';
import { ClientApiError, PaymentMethod, Language } from '@shopware-pwa/types';
import { useI18n } from 'vue-i18n';
import { useI18n as customI18n } from '@/composables/useI18n';
import { navigationId } from '@/composables/useNavigation';
import { usePaymentMethods } from '~/composables/usePaymentMethods';
import { usePackStation } from '@/composables/usePackStation';
import ApiComposableWrapper from '~/components/wrappers/ApiComposableWrapper.vue';
import { getConfig } from '~/composables/useCheckoutConfig';
import { useGoogleReCaptcha } from '~/composables/useGoogleRecaptcha';
import { useUser } from '~/composables/useUser';
import { getSrcSetForMedia } from '@shopware-pwa/helpers-next';

const { recaptcha, isRecaptchaActive } = useGoogleReCaptcha();
const { push, currentRoute } = useRouter();

//Offcanvas Cart
const isSidebarOpen = ref(false);
provide('isSidebarOpen', isSidebarOpen);

watch(currentRoute, () => {
  isSidebarOpen.value = false;
});

const config = useRuntimeConfig();
const { cartItems, subtotal, refreshCart, count: cartCount, cart } = useCart();
const { isLoggedIn, login, register, isGuestSession } = useUser();
const { resetPassword } = useCustomerPassword();
const { apiInstance } = useShopwareContext();
const { getFormattedPrice } = usePrice();
const { pushSuccess, pushWarning, pushError } = useNotifications();
const isLoading = ref(false);
const loginErrors = ref<string[]>([]);

const {
  getConfigs: getPackstationConfig,
  config: packStationConfig,
  getAddress,
} = usePackStation();
getPackstationConfig();

/**
 * Admin config settings for input fields
 */
const formConfig = ref();
const { adminConfig } = getConfig();
watch(adminConfig, () => {
  formConfig.value = adminConfig.value.data.config;
});

/**
 * Declare wishlists handlers
 */
const wishlistProduct = ref();
const { isInWishlist, addToWishlist, removeFromWishlist } =
  useProductWishlist(wishlistProduct);
const { count: wishlistCount, items: wishlistItems } = useWishlist();

const toggleToFavorites = async (product: any) => {
  wishlistProduct.value = await product;
  if (isInWishlist.value) {
    await removeFromWishlist();
    await pushSuccess(t('components.wishlist.removeFromWishlist'));
  } else {
    await addToWishlist();
    await pushSuccess(t('components.wishlist.addToWishlist'));
  }
};

/**
 * i18n languages
 */
let { locale, t } = useI18n();
const {
  languages,
  switchLanguage,
  redirectByNavigation,
  browserLanguageMismatch,
  acceptBrowserLanguage,
} = customI18n();

/**
 * Language routing modal
 */
const showLanguageRoutingModal = ref(false);
const routeModalPath = computed(() => {
  return typeof window !== 'undefined' ? window?.location?.origin : '';
});
onBeforeMount(() => {
  showLanguageRoutingModal.value = browserLanguageMismatch();
});
const closeLanguageRoutingModal = (language?: string) => {
  showLanguageRoutingModal.value = false;
  acceptBrowserLanguage(language || locale.value);
};
const goToRoutingLanguage = (path: string, locale: string) => {
  push(new URL(path).pathname);
  closeLanguageRoutingModal(locale);
};

//TODO: import from design-system
interface IOption {
  id: string | null;
  name: string;
  key?: string;
  type?: string;
  locale?: string | null;
  selected?: boolean;
  code: string;
}

const { getMainNavigation } = useNavigation();

const changeLanguage = async (language: IOption) => {
  await switchLanguage(language as Partial<Language>);
  await redirectByNavigation(
    navigationId.value as string | null | undefined,
    language as Partial<Language>
  );
};

/**
 * offcanvas cart
 */
let voucherList: any = [];

const isSpecialPricePresent = ref(false);
const regularPriceList = ref<number[]>([]);
const regularPriceSum = computed(() =>
  regularPriceList.value.reduce((a, b) => a + b, 0)
);

watch(cartItems, () => {
  regularPriceList.value = [];
  isSpecialPricePresent.value = false;

  cartItems.value?.forEach((element: any) => {
    if (element.type !== 'promotion') {
      const { regularPriceRaw, specialPriceRaw } = useCustomPrices(element);

      if (specialPriceRaw()) isSpecialPricePresent.value = true;

      regularPriceList.value.push(regularPriceRaw() as number);
    }
  });
});

const mapLineItems = computed(() => {
  const lineItems: any = [];
  voucherList = [];

  cartItems.value?.forEach((element: any) => {
    if (element.type !== 'promotion') {
      const badgeList: any = composeProductBadges(element, t);

      var size = '';
      var color = '';

      if (element.payload.definedOptions) {
        element.payload.definedOptions.find((option: any) => {
          if (option.extendedType == 'color') {
            color = option.option;
          }

          if (option.extendedType == 'size') {
            size = option.option;
          }
        });
      }

      const { regularPrice, specialPrice } = useCustomPrices(element);

      lineItems.push({
        id: element.id,
        inWishList: wishlistItems?.value?.includes(element.id),
        productName: element.label,
        src: element.cover?.url,
        srcset: element.cover?.thumbnails
          ? getSrcSetForMedia(element.cover as any)
          : '',
        brand: element.payload.manufacturer?.translated.name,
        quantity: element.quantity,
        size: size || '',
        color: color || '',
        maxQuantity: element?.quantityInformation?.maxPurchase,
        regularPrice: element?.price?.totalPrice
          ? regularPrice()
          : t('components.general.shippingFree'),
        specialPrice: specialPrice(),
        badgeList,
        isPromoItem: element.payload.is_ln_promotion ? true : false,
        seoUrl: element.payload.seoUrls
          ? `/${locale.value}/${element?.payload?.seoUrls[0]?.seoPathInfo}`
          : '',
      });
    } else if (element.type == 'promotion') {
      const voucherSubtitle = () => {
        const value = element.payload.value;
        const discountType =
          element.payload.discountType === 'percentage' ? '% ' : '€ ';
        const displayScope =
          element.payload.discountScope === 'delivery'
            ? t('components.checkout.cart.voucher.deliverySubtitle')
            : t('components.checkout.cart.voucher.cartSubtitle');
        return value + discountType + displayScope;
      };

      voucherList.push({
        id: element.id,
        code: element.referencedId,
        title: element.label,
        subtitle: voucherSubtitle(),
        price:
          element.price.totalPrice !== 0
            ? getFormattedPrice(element.price.totalPrice)
            : '',
      });
    }
  });

  return lineItems;
});

const cart2: any = cart;
const ocErrors = computed(() => {
  if (cart2?.value?.errors) {
    const errorsArray = Object.values(cart2.value.errors);

    errorsArray.map((error: any) => {
      if (error.messageKey === 'product-stock-reached') {
        error.message = t('components.ocCardSidebar.stockReached', {
          name: error.name,
          quantity: error.quantity,
        });
      }

      if (error.messageKey === 'promotion-discount-added') {
        error.message = t('components.ocCardSidebar.automaticVoucher');
      }
      return error;
    });

    return errorsArray;
  } else {
    return [];
  }
});

const { initDataLayer, pushEvents } = useJentis();

const goToCheckout = async () => {
  // check article stock
  const refreshedCart = await refreshCart();

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (
    !(refreshedCart.errors?.['error-cart-rule']?.level === 30) &&
    !isGuestSession.value
  ) {
    isSidebarOpen.value = false;
    bodyOverflow.value = false;
    push(`/${locale.value}/checkout/register/`);

    const dataLayer = initDataLayer({
      resourceType: 'frontend.checkout.confirm.page',
    });
    dataLayer.load().then((result) => pushEvents(result?.data));

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
  } else if (
    !(refreshedCart.errors?.['error-cart-rule']?.level === 30) &&
    isGuestSession.value
  ) {
    isSidebarOpen.value = false;
    bodyOverflow.value = false;
    push(`/${locale.value}/checkout/confirm/`);

    const dataLayer = initDataLayer({
      resourceType: 'frontend.checkout.confirm.page',
    });
    dataLayer.load().then((result) => pushEvents(result?.data));
  }
};

const handleProductRemove = async (product: any) => {
  isLoading.value = true;

  const dataLayer = initDataLayer({
    resourceType: 'frontend.checkout.line-item.delete',
    cartItem: {
      id: product.id,
      quantity: mapLineItems.value.filter(
        (lineItem: any) => lineItem.id === product.id
      )[0].quantity,
    },
  });
  await dataLayer.load().then((result) => pushEvents(result?.data));

  await removeCartItem(`${product.id}`, apiInstance);

  pushSuccess(t('components.ocCardSidebar.removeFromCart'));

  await refreshCart();

  isLoading.value = false;
};

const handleProductQuantity = async (data: {
  productId: string;
  quantity: number;
}) => {
  isLoading.value = true;
  pushSuccess(t('components.ocCardSidebar.updateNotification'));
  await changeCartItemQuantity(data.productId, data.quantity, apiInstance);

  await refreshCart();

  isLoading.value = false;
};

const debounceUpdate = useDebounceFn(handleProductQuantity, 800);

const showRegistrationModal = ref(false);
provide('showRegistrationModal', showRegistrationModal);

const isOpenAccount = ref(false);
provide('isOpenAccount', isOpenAccount);

const isSideMenuOpened = ref(false);

//TODO: write composable
const goToAccount = () => {
  isSidebarOpen.value = false;
  isSideMenuOpened.value = false;
  isSearchOpened.value = false;

  if (!isLoggedIn.value) {
    isOpenAccount.value = !isOpenAccount.value;
    showRegistrationModal.value = !showRegistrationModal.value;
  }

  if (isLoggedIn.value) {
    isOpenAccount.value = false;
    push(`/${locale.value}/account/`);
  }
};

// const accountLoginRoute = `/${locale.value}/login/`;
const wishlistRoute = `/${locale.value}/wishlist/`;

const isSearchOpened = ref(false);

provide('isSideMenuOpened', isSideMenuOpened);

const { getFooterCustomLinks, getFooterCategories } = useNavigation();

const { footerSocialMedia, footerBottomLinks } =
  await getFooterCustomLinks(apiInstance);

const footerCategories = ref();

// get payment methods used at footer
let paymentMethods: ComputedRef<PaymentMethod[]>;

onMounted(async () => {
  const { getPaymentMethods } = usePaymentMethods();
  paymentMethods = await getPaymentMethods({ onlyAvailable: false });

  footerCategories.value = await getFooterCategories(false, apiInstance);
});

const bodyOverflow = ref(false);

watch(
  () => isSidebarOpen.value,
  () => {
    isSidebarOpen.value
      ? (bodyOverflow.value = true)
      : (bodyOverflow.value = false);
  }
);

watch([bodyOverflow, isSideMenuOpened, showRegistrationModal], () => {
  const body = document.querySelector('body');
  if (
    bodyOverflow.value ||
    isSideMenuOpened.value ||
    showRegistrationModal.value
  ) {
    body?.classList.add('overflow-hidden');
  } else {
    body?.classList.remove('overflow-hidden');
  }
});

watch(
  () => isSideMenuOpened.value,
  () => {
    isSidebarOpen.value
      ? (bodyOverflow.value = true)
      : (bodyOverflow.value = false);
  }
);

const toggleMenu = () => {
  isSidebarOpen.value = false;
  isSearchOpened.value = false;
  // isOpenAccount.value = false;
  showRegistrationModal.value = false;
  isSideMenuOpened.value = !isSideMenuOpened.value;
  isSideMenuOpened.value
    ? (bodyOverflow.value = true)
    : (bodyOverflow.value = false);
};

const toggleOffcanvasCart = () => {
  // console.log('cart');
  isSidebarOpen.value = !isSidebarOpen.value;
  isSideMenuOpened.value = false;
  isSearchOpened.value = false;
  // isOpenAccount.value = false;
  showRegistrationModal.value = false;
  isSidebarOpen.value
    ? (bodyOverflow.value = true)
    : (bodyOverflow.value = false);

  if (isSidebarOpen.value === true) {
    const dataLayer = initDataLayer({
      resourceType: 'frontend.cart.offcanvas',
    });

    dataLayer.load().then((result) => pushEvents(result?.data));
  }

  return;
};

const toggleSearch = () => {
  isSideMenuOpened.value = false;
  isSidebarOpen.value = false;
  showRegistrationModal.value = false;
  bodyOverflow.value = false;
  // isOpenAccount.value = false;
  isSearchOpened.value = !isSearchOpened.value;
};

const closeSearch = () => {
  isSearchOpened.value = false;
};

const openAccountLogin = () => {
  isSideMenuOpened.value = false;
  isOpenAccount.value = true;
  isSidebarOpen.value = false;
  showRegistrationModal.value = false;
  isSearchOpened.value = false;
  // bodyOverflow.value = false;
  // isWishlistPageOpen2.value = true;

  if (!isLoggedIn.value) {
    push(`/${locale.value}/login/`);
    // isOpenAccount.value = !isOpenAccount.value;
    // showRegistrationModal.value = !showRegistrationModal.value;
  }
  if (isLoggedIn.value) {
    isOpenAccount.value = true;
    push(`/${locale.value}/account/`);
  }
};

const openWishlist = () => {
  isSideMenuOpened.value = false;
  isOpenAccount.value = false;
  isSidebarOpen.value = false;
  showRegistrationModal.value = false;
  isSearchOpened.value = false;
  bodyOverflow.value = false;
  push(wishlistRoute);
  isWishlistPageOpen2.value = true;
};

const isLoginModalHelperOpen = ref(false);

const storefrontUrl =
  config.brandStores[config.BRAND] ||
  (typeof window !== 'undefined' && window.location.origin) ||
  '';
const submitResetPw = async (email: string): Promise<void> => {
  try {
    const emailSent = await resetPassword({
      email: email,
      storefrontUrl: storefrontUrl ? `${storefrontUrl}/${locale.value}` : '',
    });
    if (emailSent) {
      isLoginModalHelperOpen.value = true;
      showRegistrationModal.value = false;
    } else {
      pushWarning(t('components.account.resetPassword.mailNotSent'));
    }
  } catch (error) {
    console.error('error', error);
  }
};

// Run login process on form submit
const invokeLogin = async (values: any): Promise<void> => {
  const formData: any = {
    username: values.email,
    password: values.password,
  };

  if (isRecaptchaActive()) {
    formData['_grecaptcha_v3'] = await recaptcha('login');
  }
  loginErrors.value = [];
  try {
    await login(formData);
    pushSuccess(t('components.loginForm.message.loginSuccess'));
    showRegistrationModal.value = false;
    goToAccount();
  } catch (error) {
    //TODO: error handling if login is not succesfull. TBD with PMs
    const e = error as ClientApiError;
    loginErrors.value = e.messages.map(({ detail }) => detail);
    if (e.statusCode === 403) {
      pushError(t('components.errors.default'));
    } else {
      pushError(t('components.loginForm.message.badCredentials'));
    }
  }
};

const { captureException } = useSentry();

// Run register process on form submit
const invokeRegister = async (values: any) => {
  try {
    isLoading.value = true;
    if (isRecaptchaActive()) {
      values['_grecaptcha_v3'] = await recaptcha('registration');
    }
    const response = await register(values);
    if (response) {
      goToAccount();
      showRegistrationModal.value = false;
    }
  } catch (error: any) {
    if (
      error.messages.filter(
        (error: { code: string }) =>
          error.code === 'VIOLATION::CUSTOMER_EMAIL_NOT_UNIQUE'
      ).length > 0
    ) {
      pushWarning(t('components.checkout.register.errors.emailAlreadyExists'));
    } else {
      let message =
        (error as ClientApiError)?.messages?.[0]?.detail ||
        t('components.errors.default');

      pushWarning(message);

      // captureException(error, 'registration');
    }
    //TODO: error handling if register is not succesfull. TBD with PMs
    console.log('error register', error);
  } finally {
    isLoading.value = false;
  }
};

/**
 * TODO: pass locations data, show them in map with pins, handle selection
 */
const locations = ref();
const search = (searchParams: { zipcode: string; city: string }) => {
  const response = getAddress(searchParams);
  response
    .then((data: any) => {
      data.length > 0
        ? (locations.value = data)
        : pushWarning(t('components.packStation.map.noLocationsFound'));
    })
    .catch((e) => {
      /** TODO: handle error */
    });
};

const termsUrl = `/${locale.value}/AGB`;
const dataProtectionUrl = `/${locale.value}/Datenschutz`;

/**
 * voucher
 */
const { applyVoucher, removeVoucher, voucherLoading } = useVoucher();

watch(voucherLoading, (value) => {
  isLoading.value = value;
});

/**
 * oc cart mobile menu control
 */
const isMobileMenuOpen = ref(false);
const showMobileMenu = (value: boolean) => {
  isMobileMenuOpen.value = value;
};

const successSubscribe = ref(false);
const genericFail = ref(false);
const alreadyRegistered = ref(false);
const constraintViolation = ref(false);
const error = ref(false);
/**
 * Newsletter subscription
 */
const { newsletterSubscribe, fetchContentFromNewsletterPage, htmlContent } =
  useNewsletter();
const invokeNewsletterRegistration = async (email: string) => {
  const params: any = {
    email,
    option: 'subscribe',
    storefrontUrl: storefrontUrl ? `${storefrontUrl}/${locale.value}` : '',
    brand: config.public.BRAND,
    source: 'Footer',
  };
  if (isRecaptchaActive()) {
    params['_grecaptcha_v3'] = await recaptcha('newsletter');
  }
  await newsletterSubscribe(params)
    .then(() => {
      successSubscribe.value = true;
    })
    .catch((e) => {
      console.error(e);
      error.value = true;

      if (e.statusCode === 500) {
        genericFail.value = true;
      }

      if (e.statusCode === 409) {
        alreadyRegistered.value = true;
      }

      if (e.statusCode === 400 || e.statusCode === 403) {
        constraintViolation.value = true;
      }
    });
};
// for wishlist icon active state on wishlist page
const isWishlistPageOpen2 = ref(false);

provide('isWishlistPageOpen', isWishlistPageOpen2);

watch(
  () => currentRoute.value?.name,
  (newValue) => {
    if (newValue !== 'locale-wishlist') {
      isWishlistPageOpen2.value = false;
    }
  }
);

const isAccount = () => {
  const stringToCheck = currentRoute.value?.path;
  const wordsToCheck = ['login', 'reset', 'registration'];

  const regexPattern = new RegExp(wordsToCheck.join('|'), 'i');

  if (regexPattern.test(stringToCheck)) {
    isOpenAccount.value = true;
    return true;
  } else {
    isOpenAccount.value = false;
    return false;
  }
};

onBeforeMount(() => {
  // make mobile account icon active on page load/refresh
  isAccount();
  // make mobile wishlist icon active on page load/refresh
  if (currentRoute.value?.name === 'locale-wishlist') {
    isWishlistPageOpen2.value = true;
  }
});

watch(
  () => currentRoute.value?.path,
  () => {
    isAccount();
  }
);
</script>

<template>
  <div :class="'theme-' + config.BRAND">
    <LoadingIndicator />
    <LayoutNotifications />
    <LayoutHeader
      :cart-count="cartCount"
      :wishlist-count="wishlistCount"
      :languages="languages"
      :show-mobile-search="isSearchOpened"
      @language-change="changeLanguage"
      @close-search="closeSearch"
    />
    <LayoutBreadcrumbs v-if="!isAccount()" />
    <slot />

    <OffcanvasCart
      :brand="config.BRAND"
      :cart-card-list="mapLineItems"
      :regular-price="
        !isSpecialPricePresent && !voucherList.length
          ? getFormattedPrice(subtotal)
          : getFormattedPrice(regularPriceSum)
      "
      :special-price="
        isSpecialPricePresent || voucherList.length
          ? subtotal
            ? getFormattedPrice(subtotal)
            : t('components.general.shippingFree')
          : ''
      "
      :open-offcanvas="isSidebarOpen"
      :cart-quantity="cartCount"
      :is-loading="isLoading"
      :voucher-list="voucherList"
      :errors="ocErrors"
      @apply-voucher="applyVoucher"
      @remove-discount="removeVoucher"
      @close-offcanvas-cart="isSidebarOpen = false"
      @redirect-to-checkout="goToCheckout"
      @redirect-to-products="isSidebarOpen = false"
      @remove-handler="handleProductRemove"
      @update:quantity="debounceUpdate"
      @toggle-to-favorites="toggleToFavorites"
      @show-mobile-menu="showMobileMenu"
    />
    <client-only>
      <MobileNavigation
        v-if="!isMobileMenuOpen"
        :wishlist-count="wishlistCount"
        :cart-count="cartCount"
        :active-menu="isSideMenuOpened"
        :active-cart="isSidebarOpen"
        :active-account="isOpenAccount"
        :active-search="isSearchOpened"
        @open-offcanvas-cart="toggleOffcanvasCart"
        @go-to-account-login="openAccountLogin"
        @go-to-wishlist="openWishlist"
        @open-modal-menu="toggleMenu"
        @open-modal-search="toggleSearch"
      />
    </client-only>
    <ApiComposableWrapper
      v-if="showRegistrationModal"
      use-salutations
      use-countries
      use-pack-station
    >
      <template #default="{ salutations, countries }">
        <ToggleRegistrationModal
          :centered="true"
          :modal-form="EModalForm.IS_LOGIN_FORM"
          :loqate-key="config.LOQATE_API_KEY"
          :countries="countries"
          :salutations="salutations"
          :terms-url="termsUrl"
          :data-protection-url="dataProtectionUrl"
          :pack-station-config="packStationConfig"
          :locations="locations"
          :form-config="formConfig"
          @close="(showRegistrationModal = false), (isOpenAccount = false)"
          @submit-reset-pw="submitResetPw"
          @submit-login="invokeLogin"
          @submit-registration="invokeRegister"
          @search="search"
        />
      </template>
    </ApiComposableWrapper>
    <LayoutSideMenu @close-menu="isSideMenuOpened = false" />
    <Footer
      :brand="config.BRAND"
      :copyright="$t('components.footer.copyright')"
      :categories="footerCategories"
      :social-media="footerSocialMedia"
      :payments="paymentMethods"
      :payments-title="$t('components.footer.paymentTitle')"
      :language-title="$t('components.footer.languageTitle')"
      :links="footerBottomLinks"
      :languages="languages"
      :success-subscribe="successSubscribe"
      :generic-fail="genericFail"
      :already-registered="alreadyRegistered"
      :constraint-violation="constraintViolation"
      :error="error"
      :placeholder="$t('components.footer.placeholder')"
      :newsletter-title="
        $t(
          'components.' +
            config.BRAND +
            '.newsletterSubscription.newsletterForm.title'
        )
      "
      :modal-content="htmlContent"
      @show-modal="fetchContentFromNewsletterPage"
      @language-change="changeLanguage"
      @newsletter-form-submitted="invokeNewsletterRegistration"
      @category-clicked="(link: string) => push(link)"
      @click-link="(link: string) => push(link)"
    />
    <!-- <LanguageRoutingModal
      v-show="showLanguageRoutingModal"
      :centered="true"
      :path="routeModalPath"
      :languages="languages"
      @close="closeLanguageRoutingModal"
      @blur="closeLanguageRoutingModal"
      @select="goToRoutingLanguage"
    /> -->
    <ForgotPasswordModal
      v-if="isLoginModalHelperOpen"
      centered
      :title="t('components.resetPasswordForm.resetModal.checkEmailTitle')"
      :content="t('components.resetPasswordForm.resetModal.checkEmailContent')"
      @close="isLoginModalHelperOpen = false"
    />
    <EventListener :route="currentRoute" />
  </div>
</template>
